@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy-Regular";
  src: url("../public/assets/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../public/assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
